import { useAuth } from "../hooks/useAuth";

export default function ProtectedGuard({ roles, children }) {
  const {
    auth: { user },
  } = useAuth();

  console.log(user);
  console.log(roles);

  return children;
}
