import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";

import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";

import LoadingScreen from "../components/LoadingScreen";
import AuthGuard from "../guards/AuthGuard";
import DashboardGuard from "../guards/DashboardGuard";
import ProtectedGuard from "../guards/ProtectedGuard";

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <AuthGuard>
              <Login />
            </AuthGuard>
          ),
        },
      ],
    },

    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <DashboardGuard>
          <DashboardLayout />
        </DashboardGuard>
      ),
      children: [
        {
          path: "/dashboard",
          element: <Navigate to="/dashboard/app" replace />,
        },
        { path: "app", element: <DashboardApp /> },
        {
          path: "questions",
          element: <DashboardQuestion />,
        },
        { path: "questions/create", element: <CreateQuestionPage /> },
        {
          path: "challenges",
          element: (
            <ProtectedGuard roles={["admin", "editor"]}>
              <DashboardChallenge />
            </ProtectedGuard>
          ),
        },
        {
          path: "challenges/:id/add-questions",
          element: <DashboardAddQuestionsToChallenge />,
        },
        { path: "topics", element: <DashboardTopic /> },
        { path: "sub-topics", element: <DashboardSubTopic /> },
        { path: "advertisements", element: <DashboardAdvertisement /> },
        { path: "users", element: <DashboardUser /> },
        { path: "disclaimer", element: <DashboardDisclaimer /> },
      ],
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <Navigate to="/dashboard/app" replace />,
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../features/auth/pages/Login")));

// Dashboard
const DashboardApp = Loadable(
  lazy(() => import("../features/dashboard/app/DashboardApp"))
);

const DashboardQuestion = Loadable(
  lazy(() =>
    import("../features/dashboard/app/questions/pages/DashboardQuestion")
  )
);
const CreateQuestionPage = Loadable(
  lazy(() =>
    import("../features/dashboard/app/questions/pages/CreateQuestionPage")
  )
);

const DashboardChallenge = Loadable(
  lazy(() =>
    import("../features/dashboard/app/challenges/pages/DashboardChallenge")
  )
);
const DashboardAddQuestionsToChallenge = Loadable(
  lazy(() =>
    import("../features/dashboard/app/challenges/pages/AddQuestionsToChallenge")
  )
);

const DashboardTopic = Loadable(
  lazy(() => import("../features/dashboard/app/topics/pages/DashboardTopic"))
);
const DashboardSubTopic = Loadable(
  lazy(() =>
    import("../features/dashboard/app/sub-topic/pages/DashboardSubTopic")
  )
);
const DashboardAdvertisement = Loadable(
  lazy(() =>
    import(
      "../features/dashboard/app/advertisements/pages/DashboardAdvertisement"
    )
  )
);
const DashboardDisclaimer = Loadable(
  lazy(() => import("../features/dashboard/app/disclaimer/DashboardDisclaimer"))
);
const DashboardUser = Loadable(
  lazy(() => import("../features/dashboard/app/users/pages/DashboardUser"))
);

// Error
const Page404 = Loadable(lazy(() => import("../features/error/pages/404")));
