import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack5";
import { useRef, useState } from "react";
import homeFill from "@iconify/icons-eva/home-fill";
import personFill from "@iconify/icons-eva/person-fill";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";
import { Link as RouterLink } from "react-router-dom";

import { alpha } from "@material-ui/core/styles";
import { Button, Box, Divider, MenuItem, Typography } from "@material-ui/core";

import { PATH_DASHBOARD } from "../../routes/paths";

import { MIconButton } from "../../components/@material-extend";
import MyAvatar from "../../components/MyAvatar";
import MenuPopover from "../../components/MenuPopover";
import { authDecrypt, authRemove } from "../../utils/auth-crypt";
import { useAuth } from "../../hooks/useAuth";

const MENU_OPTIONS = [
  {
    label: "Home",
    icon: homeFill,
    linkTo: "/",
  },
  {
    label: "Profile",
    icon: personFill,
    linkTo: PATH_DASHBOARD.general.app,
  },
  {
    label: "Settings",
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.general.app,
  },
];

export default function AccountPopover() {
  /**
   * states
   */
  const [open, setOpen] = useState(false);

  /**
   * hooks
   */
  const { logout } = useAuth();
  const anchorRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [data] = useState(authDecrypt());

  /**
   * selectors
   */

  /**
   * functions
   */
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      authRemove();
      logout();
      // window.location.reload();
    } catch (error) {
      enqueueSnackbar("Unable to logout", { variant: "error" });
    }
  };

  /**
   * effects
   */

  /**
   * yup and formik
   */

  /**
   * variables
   */

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {`${data?.user?.fullName}`}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
