import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";

import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { useLogin } from "../../hooks/useLogin";
import { useSnackbar } from "notistack5";
import { useAuth } from "../../../../hooks/useAuth";

export default function LoginForm() {
  /**
   * states
   */
  const [showPassword, setShowPassword] = useState(false);

  /**
   * hooks
   */
  const { setAuth, setUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { loading: loginLoading, success, data, mutate } = useLogin();

  /**
   * selectors
   */

  /**
   * functions
   */
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  /**
   * yup and formik
   */
  const LoginSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(
        /^(9|7)[0-9]{8}$/,
        "Phone number must be a valid phone number format"
      ),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        mutate(values);
      } catch (error) {
        resetForm();
      }
    },
  });

  /**
   * effects
   */
  useEffect(() => {
    if (success) {
      enqueueSnackbar("Logged in successfully", { variant: "success" });
      const token = data?.user?.token;
      delete data?.user?.token;

      setAuth({
        token: token,
      });
      setUser({
        ...data?.user,
      });
    }
  }, [success, data, enqueueSnackbar, setAuth, setUser]);

  /**
   * variables
   */
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3} mb={3}>
            <TextField
              fullWidth
              type="tel"
              label="Phone number"
              {...getFieldProps("phoneNumber")}
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              helperText={touched.phoneNumber && errors.phoneNumber}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+251</InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Password"
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loginLoading}
            disabled={loginLoading}
          >
            Login
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
}
