import { useCallback, useMemo, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";

export const AuthProvider = ({ children, token, user }) => {
  const [auth, setAuth] = useState({
    token,
    user,
  });

  const set = useCallback((token, user) => {
    setAuth({
      token,
      user,
    });
  }, []);

  const setUser = useCallback((user) => {
    setAuth((prevAuth) => ({
      ...prevAuth,
      user,
    }));
  }, []);

  const logout = useCallback(() => {
    setAuth({
      token: null,
      user: null,
    });
  }, []);

  const value = useMemo(
    () => ({ auth, setAuth: set, setUser, logout }),
    [auth, set, logout] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
