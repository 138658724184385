import { useAuth } from "../hooks/useAuth";
import createAvatar from "../utils/create-avatar";
import { MAvatar } from "./@material-extend";

export default function MyAvatar({ ...other }) {
  const {
    auth: { user },
  } = useAuth();

  return (
    <MAvatar
      src={"user.photoURL"}
      alt={"user.displayName"}
      color={user.fullName ? "default" : createAvatar(user.fullName).color}
      {...other}
    >
      {user.fullName ? user.fullName.charAt(0) : ""}
    </MAvatar>
  );
}
