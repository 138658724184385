import { Navigate } from "react-router";
import { PATH_DASHBOARD } from "../routes/paths";
import { useAuth } from "../hooks/useAuth";

export default function AuthGuard({ children }) {
  const {
    auth: { token },
  } = useAuth();

  if (token) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return children;
}
