import NotistackProvider from "./components/NotistackProvider";
import ThemeLocalization from "./components/ThemeLocalization";
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import ThemeConfig from "./theme";
import ScrollToTop from "./components/ScrollToTop";
import Router from "./routes";
import Settings from "./components/settings";
import { AuthProvider } from "./providers/auth-provider";
import { authDecrypt } from "./utils/auth-crypt";
import { useState } from "react";

export default function App() {
  /**
   * states
   */
  const [auth] = useState(authDecrypt());
  const token = auth?.user?.token;
  delete auth?.user?.token;

  /**
   * hooks
   */

  /**
   * selectors
   */

  /**
   * functions
   */

  /**
   * effects
   */

  /**
   * yup and formik
   */

  /**
   * variables
   */

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <NotistackProvider>
            <Settings />
            <ScrollToTop />
            <AuthProvider token={token || null} user={auth?.user}>
              <Router />
            </AuthProvider>
          </NotistackProvider>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
