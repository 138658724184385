import { useMutation } from "@tanstack/react-query";
import { login } from "../api/auth.api";
import { authEncrypt } from "../../../utils/auth-crypt";
import { useSnackbar } from "notistack5";

export const useLogin = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { isPending, isSuccess, data, mutate } = useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      authEncrypt(data);
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || "Failed to login", {
        variant: "error",
      });
    },
  });

  return {
    loading: isPending,
    success: isSuccess,
    data,
    mutate,
  };
};
