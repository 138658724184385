import React from "react";
import { styled } from "@material-ui/core/styles";
import { Box, Card, Stack, Container, Typography } from "@material-ui/core";

import Page from "../../../components/Page";

import { MHidden } from "../../../components/@material-extend";
import { LoginForm } from "../components";

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 654,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: theme.spacing(2, 0, 2, 2),
  padding: theme.spacing(6, 6),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

const FrameStyle = styled("div")(({ theme }) => ({
  zIndex: 9,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadiusMd,
}));

export default function Login() {
  /**
   * states
   */

  /**
   * hooks
   */

  /**
   * selectors
   */

  /**
   * functions
   */

  /**
   * effects
   */

  /**
   * variables
   */

  return (
    <RootStyle title="Login - Fetan Admin">
      <MHidden width="mdDown">
        <SectionStyle>
          <FrameStyle>
            <img src="/fetan-logo.png" alt="kelati admin login" />
          </FrameStyle>
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                {"Fetan Admin | "}
                <Typography
                  component="span"
                  variant="h4"
                  sx={{ color: "primary.main" }}
                >
                  Login
                </Typography>
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                Login into your account.
              </Typography>
            </Box>
          </Stack>

          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
