import { useState } from "react";
import Login from "../features/auth/pages/Login";
import { Navigate, useLocation } from "react-router";
import { useAuth } from "../hooks/useAuth";

export default function DashboardGuard({ children }) {
  const [requestedLocation, setRequestedLocation] = useState(null);
  const { pathname } = useLocation();

  const {
    auth: { token },
  } = useAuth();

  if (!token) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return children;
}
