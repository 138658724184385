import { createContext } from "react";

export const AuthContext = createContext({
  auth: {
    token: null,
    user: null,
  },
  setAuth: () => {},
  setUser: () => {},
  logout: () => {},
});
