import React from "react";
import ReactDOM from "react-dom/client";

import "./utils/highlight";

import "./locales/i18n";

import "simplebar/src/simplebar.css";
import "react-image-lightbox/style.css";

import "react-quill/dist/quill.snow.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import App from "./App";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import { HelmetProvider } from "react-helmet-async";
import { LocalizationProvider } from "@material-ui/lab";
import { SettingsProvider } from "./contexts/SettingsContext";
import { BrowserRouter } from "react-router-dom";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";
import { ReactQueryProvider } from "./providers/react-query-provider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SettingsProvider>
        <CollapseDrawerProvider>
          <ReactQueryProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ReactQueryProvider>
        </CollapseDrawerProvider>
      </SettingsProvider>
    </LocalizationProvider>
  </HelmetProvider>
);
