import { PATH_DASHBOARD } from "../../routes/paths";
import SvgIconStyle from "../../components/SvgIconStyle";

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

const ICONS = {
  advertisement: getIcon("ic_advertisement"),
  challenges: getIcon("ic_challenges"),
  disclaimer: getIcon("ic_disclaimer"),
  questions: getIcon("ic_questions"),
  topics: getIcon("ic_topics"),
  subTopics: getIcon("ic_sub_topics"),
  users: getIcon("ic_users"),
  roles: getIcon("ic_roles"),
  permissions: getIcon("ic_permissions"),
};

const sidebarConfig = [
  {
    subheader: "game management",
    roles: ["CHALLENGE", "QUESTION"],
    items: [
      {
        title: "challenges",
        path: PATH_DASHBOARD.general.challenges,
        icon: ICONS.challenges,
        roles: ["CHALLENGE"],
      },
      {
        title: "questions",
        path: PATH_DASHBOARD.general.questions,
        icon: ICONS.questions,
        roles: ["QUESTION"],
      },
    ],
  },
  {
    subheader: "question management",
    roles: ["TOPIC", "SUBTOPIC"],
    items: [
      {
        title: "topics",
        path: PATH_DASHBOARD.general.topics,
        icon: ICONS.topics,
        roles: ["TOPIC"],
      },
      {
        title: "sub topics",
        path: PATH_DASHBOARD.general.subTopics,
        icon: ICONS.subTopics,
        roles: ["SUBTOPIC"],
      },
    ],
  },
  {
    subheader: "ads & disclaimer",
    roles: ["ADVERTISEMENT"],
    items: [
      {
        title: "advertisements",
        path: PATH_DASHBOARD.general.advertisement,
        icon: ICONS.advertisement,
        roles: ["ADVERTISEMENT"],
      },
      {
        title: "disclaimer",
        path: PATH_DASHBOARD.general.disclaimer,
        icon: ICONS.disclaimer,
        roles: ["DISCLAIMER"],
      },
    ],
  },
  {
    subheader: "user management",
    roles: ["USER"],
    items: [
      {
        title: "users",
        path: PATH_DASHBOARD.general.users,
        icon: ICONS.users,
        roles: ["USER"],
      },
    ],
  },
];

export default sidebarConfig;
