function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    advertisement: path(ROOTS_DASHBOARD, "/advertisements"),
    disclaimer: path(ROOTS_DASHBOARD, "/disclaimer"),
    topics: path(ROOTS_DASHBOARD, "/topics"),
    subTopics: path(ROOTS_DASHBOARD, "/sub-topics"),
    questions: path(ROOTS_DASHBOARD, "/questions"),
    createQuestion: path(ROOTS_DASHBOARD, "/questions/create"),
    challenges: path(ROOTS_DASHBOARD, "/challenges"),
    addQuestionsToChallenge: (id) =>
      path(ROOTS_DASHBOARD, `/challenges/${id}/add-questions`),
    users: path(ROOTS_DASHBOARD, "/users"),
    roles: path(ROOTS_DASHBOARD, "/roles"),
    permissions: path(ROOTS_DASHBOARD, "/permissions"),
  },
};
