import axios from "axios";
import { authDecrypt, authRemove } from "./auth-crypt";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const auth = authDecrypt();

    if (auth) {
      const {
        user: { token },
      } = auth;
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      authRemove();
    }
    return Promise.reject(error);
  }
);

export default api;
