import cryptoJs, { AES } from "crypto-js";
import Cookies from "js-cookie";

/**
 * The function encrypts the data and stores it in a cookie with a 7-day expiry
 * @param {*} data - Data to be encrypted eg. token, user details
 * The cookie is set to secure in production
 * The encryption key is stored in the .env file - REACT_APP_SECRET_KEY
 * The cookie key is stored in the .env file - REACT_APP_COOKIE_KEY
 */
export const authEncrypt = (data) => {
  const cipherData = AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_SECRET_KEY
  ).toString();

  Cookies.set(process.env.REACT_APP_COOKIE_KEY, cipherData, {
    expires: 7,
    secure: process.env.NODE_ENV === "production",
  });
};

/**
 * The function decrypts the data from the cookie and returns it
 * @returns - Decrypted data from the cookie
 * The encryption key is stored in the .env file - REACT_APP_SECRET_KEY
 * The cookie key is stored in the .env file - REACT_APP_COOKIE_KEY
 * The function returns null if the cookie is not found
 */
export const authDecrypt = () => {
  const cipherData = Cookies.get(process.env.REACT_APP_COOKIE_KEY);
  if (!cipherData) {
    return null;
  }

  const bytes = AES.decrypt(cipherData, process.env.REACT_APP_SECRET_KEY);
  const originalData = bytes.toString(cryptoJs.enc.Utf8);
  return JSON.parse(originalData);
};

export const authRemove = () => {
  Cookies.remove(process.env.REACT_APP_COOKIE_KEY);
};
